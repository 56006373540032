<template>
  <div class="main-container position-relative py-md-5">
    <div class="row py-md-5 justify-content-center">
      <div class="col-12 col-md-10 col-lg-7 border" v-if="numPages > 0">
        <pdf v-for="i in numPages" :key="i" :src="src" :page="i"></pdf>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  metaInfo() {
    return this.$common.getPageMeta();
  },
  jsonld() {
    return this.$common.getJsonLd();
  },
  data() {
    return {
      src: null,
      numPages: 0,
    };
  },
  methods: {},
  created: function () {
    switch (this.$route.name) {
      case "Terms":
        this.src = pdf.createLoadingTask("/CGU.pdf");
        break;
      case "Privacy":
        this.src = pdf.createLoadingTask("/Privacy.pdf");
        break;
      case "Legal":
        this.src = pdf.createLoadingTask("/Legal.pdf");
        break;
    }
    console.log(this.$route);
  },
  mounted: function () {
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
  },
  destroyed() {},
};
</script>
